<template>
  <div>
  
    <v-card>
      <v-card-title>
        {{titulo}}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
  
      <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              <v-text-field
                v-model="total_sum"
                label="Total a sumar"
                type="number"
              ></v-text-field>
            </v-col>
  
            <v-col cols="12" sm="12" md="3">
              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="showModal"
                block
              >
                Sumar <v-icon right >
                  fas fa-calculator
                </v-icon>
              </v-btn>
            </v-col>
  
            <v-col cols="12" sm="12" md="3">
              <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="showModal2"
                block
              >
                Nueva Constancia
              </v-btn>
            </v-col>
           
  
          </v-row>
      </v-container>
  
        <!-- :items="currentItems" -->
      <v-data-table
        v-model="editedItem.selected"
        :headers="headers"
        :items="currentItems"
        :search="search"
        sort-by="date_created"
        sort-desc
        class="elevation-1"
        :loading="loading"
        loading-text="Consultando datos... Espere por favor"
        show-select
        checkbox-color="red"
        item-key="id"
        :item-selected="handleCheckboxChange"
      >
        <template v-slot:[`item.date_created`]="{ item }">
            <div>
              {{ dateFormat(item.date_created) }}
            </div>
        </template>
        <!-- <template v-slot:[`item.quantity`]="{ item }">
            <div>
              {{ numberWithCommas((parseFloat(item.quantity)).toFixed(2)) }}
            </div>
        </template> -->
  
        <template v-slot:top>
            <v-dialog v-model="dialog" max-width="1000px">
              <!-- <template v-slot:activator="{ on }">
                  <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
              </template> -->
              <v-card>
                <v-card-title>
                  <span class="headline">Generar Constancia</span>
                </v-card-title>
  
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="valid" autocomplete="off">
                      <v-row>
                        <v-col cols="12" sm="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.id_exporter"
                            :items="customers"
                            item-text="name"
                            item-value="id"
                            label="Escoge una Exportadora"
                            :rules="editedItem.id_productorRules"
                            required
                            clearable
                            rounded
                            solo
                          ></v-autocomplete>
                        </v-col>
                        <!-- 2403972
  2403975 -->
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                              v-model="editedItem.constancy_number"
                              label="Número de Constancia"
                              required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
  
                    <v-data-table
                      v-model="editedItem.selected"
                      :headers="headers_modal"
                      :items="editedItem.selected"
                      sort-by="id"
                      sort-desc
                      class="elevation-1"
                      :loading="loading"
                      loading-text="Consultando datos... Espere por favor"
                      item-key="id"
                    >
  
                    <template slot="body.append">
  
                      <tr class="gray--text">
                        <!-- <th class="title"></th> -->
                        
                        <th colspan="8" class="totales">
                        <v-row class="pt-2">
  
                          <v-col cols="12" sm="12" md="4">
                            Total Oro: {{ numberWithCommas((parseFloat(sumField('quantity'))).toFixed(2)) }}
                          </v-col>
                        </v-row>
                        </th>
                        
                      </tr>
                    </template>
                    </v-data-table>
  
                  </v-container>
                </v-card-text>
  
                <v-card-actions>
                  <v-row align="center" class="d-flex mb-2 mx-4">
                    <v-col cols="12" sm="12" md="6">
  
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
  
                      <v-btn
                        color="warning"
                        rounded
                        outlined
                        class="flex-grow-1"
                        block
                        large
                        @click="dialog = false"
                        :elevation="0"
                        :minHeight="56"
                        >Cancelar
                      </v-btn>
  
                    </v-col>
  
                    <v-col  cols="12" sm="12" md="3">
  
                      <v-btn
                        color="primary"
                        rounded
                        block
                        large
                        @click="save"
                        :loading="loading1"
                        :disabled="loading1" 
                        :elevation="0"
                        :minHeight="56"
                        >Guardar
                      </v-btn>
  
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
            
        </template>
  
        <template slot="body.append">
  
        <tr class="gray--text">
          <!-- <th class="title"></th> -->
          
          <th colspan="9" class="totales">
          <v-row class="pt-2">
  
            <v-col cols="12" sm="12" md="4">
              Total Oro: {{ numberWithCommas((parseFloat(sumField2('quantity'))).toFixed(2)) }}
            </v-col>
          </v-row>
          </th>
          
        </tr>
        </template>
  
      </v-data-table>
    </v-card>
  </div>
  </template>
  
  <script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import * as moment from 'moment';
  // import jsPDF from 'jspdf';
  
  export default {
    name: "constance",
    data: () => ({
      dialog: false,
      dialogFactor: false,
      samplings: [],
      currentItems: [],
      sumItems: [],
      items: [],
      customers: [],
      types: [],
      damages: [],
      processes: [],
      damages: [],
      process: "",
      type: "",
      total_sum: "",
      id_customer: null,
      dates: [],
      modal: false,
      loader: null,
      loading1: false,
      loading3: false,
      dialogDelete: false,
      search: "",
      loading: false,
      titulo: "Constancia de Retenciones",
      headers: [
        { text: "Fecha", align: "left", value: "date_created"},
        { text: "Número de Comprobante", value: "receipt" },
        { text: "Clave del Productor", value: "codihc" },
        { text: "Nombre", align: "left", value: "nombre" },
        // { text: "Nota de Peso", value: "coddoc" },
        // { text: "Clase de Café", value: "product" },
        // { text: "Cantidad", value: "cant" },
        // { text: "Precio", value: "precio" },
        { text: "Retención", value: "quantity" }
      ],
      headers_modal: [
        { text: "Número de Comprobante", value: "receipt" },
        { text: "Clave del Productor", value: "codihc" },
        { text: "Cantidad", value: "quantity" },
        { text: "Nombre", align: "left", value: "nombre" },
      ],
      editedIndex: -1,
  
      valid: true,
      
  
      editedItem: {
        id_exporter: null,
        constancy_number: "",
        selected: [],
        id_productorRules: [v => !!v || "Escoge una Exportadora"],
      },
      defaultItem: {
        id_exporter: null,
        constancy_number: "",
        selected: [],
        id_productorRules: [v => !!v || "Escoge una Exportadora"],
      },
    }),
  
    computed: {
      
      formTitle() {
        return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
      },
      ...mapState(["db","headers_db"]),
      dateRangeText: {
          get()
          {
              return this.dates.join(' ~ ')
          },
          set()
          {
              this.dates = []
          }
      },
    },
  
    watch: {
      // dialog(val) {
      //   val || this.close();
      // },
      loader () {
          const l = this.loader
          this[l] = !this[l]
  
          setTimeout(() => (this[l] = false), 15000)
  
          this.loader = null
      },
    },
  
    methods: {
      ...mapActions(["validateSession"]),
      // Metodos base de datos
      async getSamplings() {
          this.loading = true;
          
          const requests = [
              axios.get(this.db + 'constance_retentions', { headers: this.headers_db })
                  .then(response => {
                      let datos2 = response.data.data;
                      datos2.forEach(element => {
                          if (element.nombre == null) {
                              element.nombre = element.nombre2;
                          }
                          if (element.gold_quantity) {
                              element.quantity = (parseFloat(element.quantity) + parseFloat(element.gold_quantity)).toFixed(2);
                          }
                      });
                      this.samplings = datos2;
                  })
                  .catch(error => {
                      this.samplings = []; // Si falla, aseguramos que sea un array vacío
                      this.validateSession(error).then((resp) => { if (resp) this.getSamplings(); });
                  }),

              axios.get(this.db + 'constance_retentions_partner', { headers: this.headers_db })
                  .then(response => {
                      this.partnerSamplings = response.data.data;
                  })
                  .catch(error => {
                      this.partnerSamplings = []; // Si falla, aseguramos que sea un array vacío
                      console.error(error);
                  })
          ];

          await Promise.allSettled(requests);

          // Concatenamos aunque una de las llamadas haya fallado
          this.currentItems = [...this.samplings, ...this.partnerSamplings];

          this.loading = false;
          console.log('this.currentItems', this.currentItems);
      },
  
      async getCustomers(){
        await axios.get(this.db + 'exporters',
          {
            headers: this.headers_db
          }).then(response => {
            this.customers = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
          });
      },
  
      async getTypes(){
        await axios.get(this.db + 'types',
          {
            headers: this.headers_db
          }).then(response => {
            this.types = response.data.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.validateSession(error).then((resp) => { if(resp) this.getTypes(); })
          });
      },
      // FIN Metodos base de datos
  
      // Metodos Generales
      dateFormat(date) {
        return moment(date).format('DD/MM/YYYY');
      },
      
      sumField(key) {
        // sum data in give key (property)
        return this.editedItem.selected.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
      },
      sumField2(key) {
        // sum data in give key (property)
        return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
      },
  
      handleCheckboxChange(item) {
        // Manejar el evento del cambio de checkbox aquí
        console.log('Elementos seleccionados:', item);
      },
  
  
      showModal2() {
        this.dialog = true;
        console.log(this.editedItem.selected);
      },
  
      showModal() {
        if (this.total_sum == '') {
          this.$store.dispatch("setSnackbar", {
              text: "Escribe una cantidad a sumar",
              color: "warning"
          });
          return;
        }

        console.time("temp")
        // let candidates = [];

        const candidates = this.currentItems.filter(item => parseFloat(item.quantity) <= parseFloat(this.total_sum));
        
        let sumItems = this.combinationSum(candidates, parseFloat(this.total_sum))[0];
        // console.log(sumItems == undefined);
        if (sumItems != undefined) {
          this.editedItem.selected.push(...sumItems);
          this.$store.dispatch("setSnackbar", {
              text: "Comprobantes encontrados",
          });
        }
        console.timeEnd("temp")
      },
  
      combinationSumRecursive(
        candidates,
        remainingSum,
        finalCombinations = [],
        currentCombination = [],
        startFrom = 0
      ) {
        console.log('remainingSum', remainingSum);
        
      if (finalCombinations.length > 0) {
        return;
      }
  
      if (parseFloat(remainingSum) < 0) {
        console.log(remainingSum, "sum < 0", finalCombinations);
        // By adding another candidate we've gone below zero.
        // This would mean that the last candidate was not acceptable.
        // console.log(currentCombination);
        return finalCombinations;
      }
  
      if (parseFloat(remainingSum) === 0) {
  
        console.warn("sum = 0", finalCombinations);
        // const set = new Set(currentCombination);
        // if(set.size === currentCombination.length) {
          // console.log(currentCombination);
          finalCombinations.push(currentCombination.slice());
        // }
        // If after adding the previous candidate our remaining sum
        // became zero - we need to save the current combination since it is one
        // of the answers we're looking for.
        
  
        // finalCombinations = currentCombination;
        return finalCombinations;
      }
  
      // If we haven't reached zero yet let's continue to add all
      // possible candidates that are left.
      for (
        let candidateIndex = startFrom;
        candidateIndex < candidates.length;
        candidateIndex += 1
      ) {
        const currentCandidate = candidates[candidateIndex];
        // Let's try to add another candidate.
        // console.log('currentCandidate', currentCandidate)
  
        const personaBuscada = currentCombination.find(item => parseInt(item.id) === parseInt(currentCandidate.id));
        // console.log(personaBuscada);
        if (!personaBuscada) {
          currentCombination.push(currentCandidate);
          // Explore further option with current candidate being added.
          this.combinationSumRecursive(
            candidates,
            parseFloat(parseFloat(remainingSum) - parseFloat(currentCandidate.quantity)).toFixed(2),
            finalCombinations,
            currentCombination,
            candidateIndex
          );
    
          // BACKTRACKING.
          // Let's get back, exclude current candidate and try another ones later.
          currentCombination.pop();
        }
      }
  
      return finalCombinations;
    },
  
    combinationSum(candidates, target) {
      console.log(target);
      return this.combinationSumRecursive(candidates, target);
    },
  
      getByCustomer() {
        console.log(this.id_customer, this.samplings);
        if (this.id_customer) {
          this.currentItems = this.samplings.filter(doc => {
            return doc.codprov == this.id_customer;
          });
        }
        else {
          this.currentItems = this.samplings;
        }
      },
      
      getSettings() {
        // this.loader = 'loading3'
        this.getTypes();
        this.dialogFactor = true;
      },
  
      close() {
        this.dialog = false;
        this.dialogFactor = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
        }, 300);
      },

      save() {
        // this.generatePdf(this.editedItem);
        if (this.$refs.form.validate()) {
          let selected = this.editedItem.selected;
          let total_ret = 0;
          this.loader = 'loading1'
          selected.forEach(element => {
            total_ret += parseFloat(element.quantity);
          });
  
          axios.post(this.db + 'constancy/add', {
            constancy_number: this.editedItem.constancy_number,
            id_exporter: this.editedItem.id_exporter,
            total_ret:total_ret,
            ids_receipt: selected
          },
          {
            headers: this.headers_db
          })
          .then((response) => {
            console.log(response.data.message);
            this.$store.dispatch("setSnackbar", {
              text: response.data.message,
            });
            this.getSamplings();
            this.total_sum = '';
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("setSnackbar", {
              text: error,
            });
            this.validateSession(error).then((resp) => { if(resp) this.save(); })
          });
        }
      },
  
      numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    async mounted() {
      this.loading = true;
      await this.getSamplings();
      this.getCustomers();
    },
  };
  </script>
  
  <style lang="scss">
    .totales {
      font-size: medium !important;
    }
  </style>
  